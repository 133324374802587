/* html {
  height: 100%;
}
#root {
  height: 100%;
}
body {
  height: 100%;
  position: relative;
}
body:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ec1c24;
  opacity: 0.7;
  z-index: 1;
}

video.bg-video {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  z-index: 0;
}

@media (pointer: coarse) and (hover: none) {
  body {
    background: url("./assets/img/bg-mobile-fallback.jpg") #ec1c24 no-repeat
      center center scroll;
    background-size: cover;
  }
  body video {
    display: none;
  }
}
.masthead {
  position: relative;
  overflow: hidden;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.masthead:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.85);
}
.masthead .masthead-content {
  position: relative;
  max-width: 40rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.masthead .masthead-content h1,
.masthead .masthead-content .h1 {
  font-size: 2.5rem;
}
.masthead .masthead-content p {
  font-size: 1.2rem;
}
.masthead .masthead-content p strong {
  font-weight: 700;
}
.masthead .masthead-content .input-group-newsletter input {
  height: auto;
  width: 100%;
  font-size: 1rem;
  padding: 1rem;
}
.masthead .masthead-content .input-group-newsletter button {
  font-size: 0.85rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: calc(1rem + 2px);
}

@media (min-width: 992px) {
  .masthead {
    height: 100%;
    width: 75vw;
    min-height: 0;
    padding-bottom: 0;
  }
  .masthead:before {
    transform: skewX(-9deg);
    transform-origin: top right;
  }
  .masthead .masthead-content {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 2rem;
    padding-right: 9rem;
  }
  .masthead .masthead-content h1,
  .masthead .masthead-content .h1 {
    font-size: 2rem;
  }
  .masthead .masthead-content p {
    font-size: 1rem;
  }
}
@media (min-width: 1200px) {
  .masthead {
    width: 65vw;
  }
}
.social-icons {
  position: relative;
  z-index: 2;
}
.social-icons .btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 4rem;
  width: 4rem;
  border-radius: 100rem;
}

@media (min-width: 992px) {
  .social-icons {
    position: absolute;
    height: 100%;
    top: 0;
    right: 2.5rem;
    width: auto;
  }
} */

.sidebar_container {
  width: 350px;
  overflow-y: scroll;
  height: 100vh;
}
/* .sidebar_container button {
  border: 1px solid rgba(0, 0, 0, 0.03);
  padding: 10px;
  border-radius: 10px;
  outline: none;
  display: none;
  transition: 0.4s;
  z-index: 100000;
}

@media (max-width: 700px) {
  .main_logo {
    display: flex;
    justify-content: flex-start !important;
  }
  .sidebar_container button {
    display: block;
  }
  .resposive_sidebar {
    position: fixed;
    left: -350px;
    transition: left 0.3s ease;
    width: 350px;
  }
  .resposive_sidebar.open {
    left: 0;
  }
  .main_video {
    justify-content: space-evenly !important;
    width: 100vw;
    padding: 2rem;
  }
} */

/* Track */
*::-webkit-scrollbar {
  width: 6px;
  border-radius: 3px;
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background: transparent;
}

*:hover::-webkit-scrollbar-thumb {
  background: rgba(22, 24, 35, 0.06);
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: rgba(22, 24, 35, 0.06); /* Color of the scroll handle on hover */
}

.sidebar_box {
  box-shadow: 0px 112px 45px rgba(0, 0, 0, 0.01),
    0px 63px 38px rgba(0, 0, 0, 0.02), 0px 28px 28px rgba(0, 0, 0, 0.03),
    0px 7px 15px rgba(0, 0, 0, 0.04), 0px 0px 0px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  background: white;
}
.main_logo {
  display: flex;
  justify-content: center;
}
.main_logo img {
  width: 11rem;
}

.left_icon {
  color: rgba(0, 0, 0, 0.2);
}
.left_icon > span {
  color: rgba(0, 0, 0, 0.2);
  font-weight: 400;
  font-size: 18px;
  margin-left: 20px;
}
.tab.active > .active_bar {
  width: 4px;
  height: 32px;
  border-radius: 16px;
}
.tab.active .left_icon > span {
  font-weight: 600;
  color: #000000;
}
.tab.active .left_icon > .icon {
  color: #ec1c24;
}
.login_text_box {
  background: rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  text-align: center;
}
.profile-img > img {
  width: 45px;
  border-radius: 50%;
}
.profile-info {
  color: rgba(22, 24, 35, 0.75);
  font-size: 15px;
  margin-top: 5px;
}
.more_btn {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}
.more_btn::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1.1px;
  background: rgba(22, 24, 35, 0.12);
  top: 50%;
  transform: translateY(-50%);
}
.more_btn > div {
  z-index: 100;
  background: white;
}
.more_btn button {
  font-size: 12px;
}
.dot {
  height: 5px;
  width: 5px;
  background-color: #000;
  border-radius: 100%;
  margin: 5px;
}
.lists {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.lists a {
  color: black;
  text-decoration: none;
}

.navbar {
  margin: 0 0.7rem 0 0.7rem;
  box-shadow: 0px 112px 45px rgba(0, 0, 0, 0.01),
    0px 63px 38px rgba(0, 0, 0, 0.02), 0px 28px 28px rgba(0, 0, 0, 0.03),
    0px 7px 15px rgba(0, 0, 0, 0.04), 0px 0px 0px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
}
.navbar-brand {
  font-size: 18px;
  font-weight: 400;
}
.search_input {
  border-radius: 56px;
  background: rgba(0, 0, 0, 0.02);
  padding: 10px 20px;
}
.search_input > input {
  font-size: 15px;
  background: none;
  outline: none;
  width: 100%;
  border: none;
}
.nav_btn {
  width: 140px;
}
.profile_hidden {
  display: none;
  position: absolute;
  top: 4.2rem;
  right: -1rem;
}
.profile_display {
  display: block;
}
.profile_dropdown_inner {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.profile_dropdown_inner:hover {
  background-color: #f0f0f0;
}
/* video card  */
.main_video {
  max-width: 600px;
  background: white;
}
.profile_details > p {
  font-size: 15px;
}
.profile_details > span {
  font-size: 12px;
  color: #161823;
}
.cardSpan {
  font-weight: 600;
}
.about_music {
  font-size: 14px;
  font-weight: 500;
}
.followers {
  font-size: 12px;
  font-weight: 500;
}
.avatar.video-card-avatar {
  width: 50px;
  height: 50px;
}
.avatar.search_avatar {
  width: 30px;
  height: 30px;
}
.video-player {
  width: 100%;
  position: relative;
}
.video-player > video {
  background: black;
  width: 100%;
  height: 100%;
  min-height: 400px;
  max-height: 500px;
  object-fit: contain;
}
.video-player:hover > .video-controls {
  visibility: visible;
}
.video-controls {
  position: absolute;
  bottom: 40px;
  width: 100%;
  padding: 0 20px;
  color: white;
  visibility: hidden;
}
.video-controls.visible {
  visibility: visible;
}
.video-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}
/* Modal  */
.auth_container {
  position: fixed;
  width: 100vw;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 500;
}
.modal_container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_modal {
  background: white;
  width: 500px;
  animation: showModal linear 0.5s;
}
.main_modal.removeModal {
  animation: hideModal linear 0.5s;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

@keyframes showModal {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes hideModal {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
.actions_btn {
  background: #f7f5f5;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 0.2s linear;
}
.actions_btn.active {
  background: #ee353b;
  color: white;
}
.home_page {
  overflow-y: scroll;
  height: calc(100vh - 80px);
  width: 100%;
}
.home_page::-webkit-scrollbar {
  width: 6px;
  border-radius: 3px;
}
.home_page::-webkit-scrollbar-thumb {
  background: rgba(22, 24, 35, 0.06);
}
/* Handle on hover */
.home_page::-webkit-scrollbar-thumb:hover {
  background: rgba(22, 24, 35, 0.06); /* Color of the scroll handle on hover */
}
.login_with {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
  cursor: pointer;
  position: relative;
}
.login_with .auth_icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: grid;
  place-items: center;
}
.login_with .auth_icon > img {
  width: 20px;
  color: aquamarine;
}

.login_with p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #000;
}
.login_link {
  color: #ec1c24 !important;
  text-decoration: none;
}
.login_link:hover {
  text-decoration: underline;
}
.crossIcon {
  background: #eee;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.btn-secondary {
  background-color: rgba(22, 24, 35, 0.06) !important;
}
.input_container input {
  border-radius: 2px;
  background-color: rgba(22, 24, 35, 0.06);
  border: 1px solid rgba(22, 24, 35, 0.12);
  padding-inline-start: 12px;
  line-height: 100%;
  outline: none;
  font-size: 14px !important;
  caret-color: rgb(254, 44, 85);
  padding-inline-end: unset;
  width: 100%;
  position: relative;
}
.input_container {
  height: 44px;
  width: 100%;
}
.close_eye {
  position: absolute;
  right: 17px;
  top: 13px;
  cursor: pointer;
}
.code {
  font-size: 14px !important;
  cursor: pointer;
  background: #ec1c24;
  color: white;
  font-weight: 500;
}
.code:disabled {
  color: rgb(180, 180, 183);
  background: #eee;
}
.phone_login_text {
  text-decoration: none;
  color: #000 !important;
  font-weight: 400;
  font-size: 12px;
}
.phone_login_text:hover {
  text-decoration: underline;
}
.login_form button {
  border: none;
  outline: none;
  border-radius: 2px;
}

.spinner {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid rgb(2, 0, 36);
  border-width: 90%;
}

@keyframes ldio-6g08kqzye5a {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.ldio-6g08kqzye5a div {
  position: absolute;
  width: 78px;
  height: 78px;
  border: 8px solid #ffffff;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-6g08kqzye5a div {
  animation: ldio-6g08kqzye5a 1s linear infinite;
  top: 50px;
  left: 50px;
}
.loadingio-spinner-rolling-x0a55a0iw5 {
  width: 20px;
  height: 20px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-6g08kqzye5a {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.2);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-6g08kqzye5a div {
  box-sizing: content-box;
}
.video-content-box {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #979797;
}

.video-content-box * {
  margin: 0;
}
.video-content-box > p {
  color: black;
  font-weight: 500;
  cursor: pointer;
}
.video-content-box > p:hover {
  text-decoration: underline;
}
.video_container {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
}
.video_container label {
  color: black;
  font-weight: 500;
}
.video_container input {
  border: 1px solid #979797;
  border-radius: 5px !important;
  outline: none;
  padding: 10px 20px;
  margin-top: 5px;
  font-size: 15px;
}

.video_container select {
  border: 1px solid #979797;
  border-radius: 5px !important;
  outline: none;
  padding: 10px;
  font-weight: 400;
  margin-top: 8px;
  font-size: 15px;
}
.video_container .checkbox {
  width: 15px;
  height: 16px;
  margin-right: 10px;
  margin-top: 0;
}
.upload_video_btn {
  background: #ee353b;
  border: none;
  outline: none;
  min-width: 150px;
  padding: 10px;
  color: white;
  border-radius: 4px;
}
.upload_video_btn.outline {
  border: 1px solid #979797;
  background: none;
  color: black;
}

@media (max-width: 1200px) {
  .main_video {
    margin-top: 3rem !important;
  }
}
@media (max-width: 900px) {
  .main_video {
    justify-content: space-evenly !important;
  }
}
@media (max-width: 800px) {
  .video_img {
    width: 200px;
    height: 350px;
  }
}

/* about us */
.white_cards {
  width: 22rem;
  border: none;
  color: gray;
  font-weight: 500;
  font-size: 14px;
  padding: 1rem;
  box-shadow: 0px 112px 45px rgba(0, 0, 0, 0.01),
    0px 63px 38px rgba(0, 0, 0, 0.02), 0px 28px 28px rgba(0, 0, 0, 0.03),
    0px 7px 15px rgba(0, 0, 0, 0.04), 0px 0px 0px rgba(0, 0, 0, 0.04);
}
.red_cards {
  width: 30rem;
  height: 16rem;
  color: #eee;
  font-size: 14px;
  border: none;
  padding: 1rem;
}
/* coming_soon */
.coming_soon {
  background-image: url("./assets/img/comingSoon.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.cmS_heading {
  font-size: 3rem !important;
  font-weight: 300;
}
.mobile_img {
  display: none;
}
.about_us_heading {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 15px;
}
.about_video > video {
  width: 50%;
  object-fit: cover;
  height: 100%;
}
.responsive_nav {
  display: none;
}
.responsive_home {
  display: none;
}

@media (max-width: 1000px) {
  .coming_soon {
    background-image: url("./assets/img/dots.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
  }
  .coming_soon > div {
    margin: auto !important;
  }
  .mobile_img {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
  }
  .mobile_img > img {
    width: 100%;
    height: 10rem;
    object-fit: cover;
  }
}
@media (max-width: 500px) {
  .coming_soon > div {
    width: 90% !important;
  }
  .cmS_heading {
    font-size: 2rem !important;
  }
  .comingsoon_subheading {
    font-size: 0.6rem;
    margin: 0 !important  ;
  }
  .comingsoon_desc {
    font-size: 0.6rem !important;
  }
}

@media (max-width: 350px) {
  .coming_soon > div {
    width: 100% !important;
    padding: 0 10px !important;
  }
  .input_container input {
    width: 100% !important;
  }
}
/* footer */
.footer_images {
  border-bottom: 1px solid #f7f5f5 !important;
}
.footer_images > img {
  height: 4rem;
  cursor: pointer;
}
.footer_headings {
  /* flex-basis: 25%; */
}
.footer_headings h6 {
  font-size: 0.9rem;
}
.footer_headings span {
  font-size: 0.8rem;
  cursor: pointer;
  line-height: 25px;
  font-weight: 500;
  color: #cccccc;
  padding-bottom: 5px;
}
.footer_headings span:hover {
  text-decoration: underline;
}
/* support */
/* .support_img {
  background-image: url("./assets/img/supportImg.svg");
  background-repeat: no-repeat;
  background-position: top;
  height: 350px !important;
  background-size: cover;
  width: 100% !important;
}
.support_input {
  background-color: transparent;
  outline: none;
  border-radius: 27px;
  width: 100%;
  border: 2px solid #979797;
}
.support_input::placeholder {
  font-size: 1.5rem;
}
.support_heading {
  font-size: 3rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  font-weight: bold;
}
.support_boxes {
  box-shadow: 2px 4px 20px rgba(34, 90, 89, 0.2);
  width: 30rem;
  border-radius: 10px;
}
.box-heading {
  font-size: 1.9rem;
  font-weight: 500;
  margin-top: 13px;
}
.support_text {
  color: rgb(179, 178, 178);
  font-size: 14px;
  letter-spacing: 0.6px;
} */
.support_img {
  background-image: url("./assets/img/supportImg.svg");
  background-repeat: no-repeat;
  background-position: top;
  height: 350px !important;
  background-size: cover;
  width: 100% !important;
}

.support_input {
  background-color: transparent;
  outline: none;
  border-radius: 27px;
  width: 100%;
  border: 2px solid #979797;
  font-size: 1.5rem;
}

.support_heading {
  font-size: 3rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  font-weight: bold;
}

.support_boxes {
  box-shadow: 2px 4px 20px rgba(34, 90, 89, 0.2);
  width: 30rem;
  border-radius: 10px;
}

.box-heading {
  font-size: 1.9rem;
  font-weight: 500;
  margin-top: 13px;
}

.support_text {
  color: rgb(179, 178, 178);
  font-size: 14px;
  letter-spacing: 0.6px;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .support_boxes {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .support_img {
    height: 250px !important;
  }

  .support_input {
    font-size: 1rem;
  }

  .support_heading {
    font-size: 2rem;
  }

  .support_boxes {
    width: 100%;
  }

  .box-heading {
    font-size: 1.6rem;
  }

  .support_text {
    font-size: 12px;
  }
}

/* contact */

.inner_contact {
  width: 54rem;
  box-shadow: 2px 4px 20px rgba(34, 90, 89, 0.2);
  margin-bottom: 5rem;
}
.inner_contact h6 {
  font-size: 1.4rem;
  font-weight: 400;
}
.link {
  color: #ec1c24;
  font-size: 15px;
  font-weight: 500;
}
.link:hover {
  text-decoration: underline !important;
  color: #ec1c24;
}
.inner_contact img {
  width: 3.5rem;
  height: 3.5rem;
}
/* safety */
.nav-link {
  font-size: 13px;
  color: #000000;
  font-weight: 500;
  margin-left: 6px;
}
.safety_input {
  position: relative;
  background-color: #eee;
  border-radius: 25px;
  border: none;
  outline: none;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}
.search_iconSafety {
  z-index: 1;
  position: absolute;
  top: 1.7rem;
  right: 15.7rem;
}
.safety_img {
  background-image: url("./assets/img/safetyCenter.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 20rem;
}
.moving_text {
  width: 100%;
  overflow-x: scroll;
  background-color: #eee;
}
.btn_main {
  cursor: pointer;
  margin-left: 2rem;
  text-wrap: nowrap;
  border-radius: 30px;
  padding: 4px 20px;
  background-color: white;
  width: 100%;
}
.btn_text {
  font-size: 1.3rem;
}
.safety_partners {
  background-image: url(https://sf16-va.tiktokcdn.com/obj/eden-va2/glkuhuyloeh7hupn/SafetyCenter-PartnersBanner_1440x436.png);
  height: 27rem;
  background-size: cover;
  width: 100%;
  margin-top: 5.5rem;
}
/* guidlines */

.side_text {
  font-size: 13px;
}
.guideline_text {
  font-size: 14px;
}

/* privacy policy */
.privacy_left {
  position: sticky;
  top: 110px;
  height: 100vh;
}
.privacy_nav {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1100;
}

/* upload page  */

.upload_btn {
  background-color: rgba(0, 0, 0, 0.04);
  font-size: 15px;
  color: rgba(0, 0, 0, 0.7);
  width: 144px;
}
.upload_btn:hover {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
.circle-nm {
  cursor: pointer !important;
  background: #e7e8fe;
  border-radius: 100%;
  width: 28px;
  height: 28px;
  text-align: center;
}
.circle-nm img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}
.upload_container {
  box-shadow: 0px 0px 10px 3px rgb(0 0 0 / 8%);
  min-height: 80vh;
  padding: 4rem 3rem 1rem 3rem;
  margin-top: 1.6rem;
  border-radius: 10px;
}
.border_box {
  /* padding-top: 4rem; */
  border: 2px dotted rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  border-radius: 10px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
}
.upload_text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.border_box h5 {
  font-size: 17px;
}
.border_box p {
  font-size: 14px;
}

/* Profile page  */
.responsive_container {
  display: none !important;
}
.profile_container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar_container {
  /* box-shadow: 0px 112px 45px rgba(0, 0, 0, 0.01),
    0px 63px 38px rgba(0, 0, 0, 0.02), 0px 28px 28px rgba(0, 0, 0, 0.03),
    0px 7px 15px rgba(0, 0, 0, 0.04), 0px 0px 0px rgba(0, 0, 0, 0.04); */
  box-shadow: 2px 4px 20px rgba(34, 90, 89, 0.2);

  border-radius: 30px;
  /* height: 550px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  background-color: #ffffff;
}
.left_avatar {
  background: rgba(0, 0, 0, 0.02);
  border-radius: 20px;
  width: 48%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.8rem;
  height: 24rem;
}

.left_avatar p {
  background: rgba(0, 0, 0, 0.03);
  width: 100%;
  border-radius: 20px;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  padding: 8px 0;
  color: #161823;
}
.left_avatar img {
  width: 150px;
  object-fit: cover;
  height: 306px;
}
.right_avatar {
  width: 48%;
  height: 100%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;

  padding: 0.8rem;
  height: 24rem;
}

.avatar_images {
  margin-top: 1rem;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #ec1c24 #ffffff;
  scrollbar-border-radius: 10px;
}

.avatar_images::-webkit-scrollbar {
  width: 8px;
}

.avatar_images::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
}

.avatar_images::-webkit-scrollbar-thumb {
  background-color: #ec1c24;
  border-radius: 10px;
}

/* .avatar_images::-webkit-scrollbar-thumb:hover {
  background-color: #333333;
} */

.avatar_images .image {
  background: rgba(0, 0, 0, 0.02);
  width: 22%;
  cursor: pointer;
  border-radius: 20px;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.image img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.top_text p {
  font-weight: 600;
  font-size: 18px;
  text-align: start;
  padding: 8px 0;
  color: #161823;
}
.top_text button {
  background: #ec1c24;
  border-radius: 20px;
  width: 100px;
  height: 33px;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
}
.top_text button:hover {
  background-color: #ee353b;
  color: white;
}
.right_avatar .heading {
  background: rgba(0, 0, 0, 0.03);
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-radius: 100px;
  padding: 10px;
}
.heading span {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #ec1c24;
  cursor: pointer;
}
.heading img {
  width: 8px;
}
.heading div {
  font-size: 18px;
  font-weight: 600;
}
.disabled {
  background-color: rgb(177, 174, 174) !important;
}

/* media queries  */

.avatar_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 2px 4px 20px rgba(34, 90, 89, 0.2);
  padding: 2rem;
  border-radius: 30px;
  width: 30rem;
}
.first_box {
  width: 100% !important;
}
.scroll {
  overflow-y: auto;
  overflow-x: scroll;
}
.scroll::-webkit-scrollbar {
  height: 8px !important;
}

/* home page responsive design  */
.responsive_home {
  background-color: rgba(0, 0, 0, 0.02);
  padding-top: 1rem !important;
  padding: 2rem;
  height: auto;
  display: none;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
}
.home_bar {
  border-radius: 20px;
  box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 8%);
  justify-content: space-between;
  padding: 5px 24px;
  border-radius: 40px;
  background-color: #ffffff;
  width: 100%;
}
.main_home {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
}
.login_container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
  z-index: 1000001;
  transition: 0.3s;
}
@media (max-width: 1250px) {
  .support_boxes {
    width: 100% !important;
  }
}
.responsive-chat {
  display: none;
}
.chat-header > h3 {
  font-size: 1.4rem;
  font-weight: 700;
}
.card {
  height: calc(100vh - 114px);
  overflow: hidden;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  position: relative;
}
.chat-profile > img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  object-fit: cover;
}
.chat-picture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}
.active-chat {
  background: #ebebeb;
}
.bg-gray {
  background: rgb(248, 248, 248);
}
.texting-section {
  overflow-y: scroll;
  margin-bottom: 5rem;
}
.chat-section {
  overflow-y: scroll;
}
.texting-header {
  border-bottom: 1px solid #ebebeb;
}
.card > form {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.message-input {
  flex-basis: 1;
  width: 100%;
  display: flex;
  background: #ebebeb;
  /* outline: 1px solid #b7b7b7; */
  border-radius: 10px;
  align-items: center;
}
.message-input > input {
  outline: none;
  border: none;
  padding: 10px 0;
  font-size: 0.8rem;
}
.message-box {
  border-top: 1px solid #ebebeb;
  background: white;
}
.send-btn {
  border: none;
  outline: none;
  background: none;
  color: #ec1c24;
}
.back-icon {
  width: 40px;
  height: 40px;
  background: #ebebeb;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.back-icon a {
  color: black;
}
.settings-modal > .modal-dialog {
  max-width: 600px;
}
.custom-modal {
  max-width: 700px !important;
}
.settings-text > h4 {
  font-size: 0.9rem;
  font-weight: 600;
}
.settings-text > p {
  font-size: 0.7rem;
  color: #979797;
}
.radio-button {
  display: flex;
  align-items: center;
}
.radio-button > input {
  width: 20px;
  height: 20px;
  background-color: #ec1c24;
  color: #ec1c24;
  accent-color: #ec1c24;
  cursor: pointer;
}
.radio-button > label {
  margin-left: 10px;
  cursor: pointer;
  font-size: 0.9rem;
}
.settings-btn {
  background: none;
  padding: 8px 0;
  width: 12rem;
  font-weight: 600;
  border-radius: 3px;
  border: none;
}
.cancel-btn {
  border: 1px solid #cccccc;
  margin-right: 20px;
}
.cancel-btn:hover {
  background: #ebebeb;
}
.save-btn {
  background: #ee353b;
  color: white;
}
.save-btn:hover {
  background: #ec1c24;
}
.about_card {
  height: 100% !important;
}
.full-video-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 150;
}
.full-video-box {
  background: #000;
  position: relative;
  display: flex;
  justify-content: center;
}
.video-box-controls {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  color: white;
  background: #535353;
  position: absolute;
  cursor: pointer;
  font-weight: bold;
}
.video-box-controls.box-close {
  left: 20px;
  top: 20px;
}
.video-box-controls.box-menu {
  right: 20px;
  top: 20px;
}
.video-box-controls.volume-icon {
  right: 20px;
  bottom: 20px;
}
.controller-icons {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.controller-icons > div {
  position: relative;
  margin: 15px 0;
}
.video-info-box {
  background: white;
  position: relative;
  height: 100vh;
}
.video-user-info {
  padding: 10px;
  border-radius: 10px;
  margin: 20px;
  background: #f8f8f8;
}
.emojis-input {
  border-top: 1px solid #979797;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  z-index: 50;
  background: white;
}
.emojis-input span {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  /* margin: 1px; */
  display: grid;
  place-items: center;
  font-size: 25px;
  transition: all 0.2s linear;
}
.emojis-input span:hover {
  background: #eaeaea;
}

@media (max-width: 1000px) {
  .responsive_nav {
    display: block;
  }
  .res_nav {
    border-radius: 0 !important;
    width: 100vw !important;
    height: auto;
  }
  .mainLayout {
    padding: 0 !important;
  }
  .main_nav {
    display: none;
  }

  .responsive_container {
    display: block !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .main_container {
    display: none;
  }
  .safety_privacy {
    flex-wrap: wrap;
    padding: 0 2rem 2rem 2rem;
  }
  .sp_detail {
    width: 100% !important;
  }
  .sp_detail .card-body {
    padding: 0 !important;
  }
  .safety_partners {
    justify-content: flex-end !important;
  }
  .chat_box {
    max-width: 100% !important;
    /* margin: 0 !important; */
  }
  /* .chatbox {
    width: 30rem;
  } */
  .chat_box {
    width: 100% !important;
  }
  .chatUser {
    /* width: 19rem !important; */
  }
}
@media (max-width: 900px) {
  .video_text_box {
    flex-direction: column !important;
    width: 100vw !important;
  }
  .about_video {
    width: 100% !important;
  }
  .about_text {
    width: 100% !important;
    margin-top: 20px;
    text-align: center;
    padding: 0 !important;
  }
  .about_us_heading p {
    padding: 2rem !important;
  }
  .upload_input {
    display: none;
  }
  .chatbox {
    margin-right: 10px;
  }

  .guides {
    width: 90% !important;
    width: 90% !important;
  }
}
@media (max-width: 768px) {
  .responsive-chat {
    display: block;
  }
  .chat-body {
    display: none;
  }
  .footer_headings {
    flex-basis: 50%;
  }
  .safety_partners h1 {
    font-size: 1rem !important;
  }
  .safety_partners p {
    font-size: 10px;
  }
  .contact_info {
    flex-wrap: wrap !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 1rem !important;
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .full-width {
    width: 68% !important;
  }
}
@media (max-width: 576px) {
  .footer_headings {
    flex-basis: 100%;
  }
  .footer_detail {
    flex-direction: column !important;
    justify-content: center !important;
    padding-left: 2rem;
  }
  .inner_contact {
    width: 100%;
  }
  .helpful {
    font-size: 16px !important;
  }
}
@media (max-width: 500px) {
  .video_img {
    width: 100% !important;
    height: 60vh;
  }
  .sidebar_container {
    display: none;
  }
  .responsive_home {
    display: block;
    padding: 1rem;
  }
  .home_page {
    display: none;
  }
  .follow {
    font-size: 11px;
  }
  .profile_details > p {
    font-size: 12px;
  }
  .cardSpan {
    font-weight: 600;
    font-size: 13px;
  }
  .avatar_box {
    width: 22rem !important;
  }
  .avatar_images .image {
    padding: 0.4rem !important;
  }
  .image img {
    width: 50px;
    height: 50px;
  }
  .container .form-select {
    width: 50% !important;
    margin-bottom: 1rem !important;
  }
  .safety_update h1 {
    font-size: 2rem !important;
  }
  .sp_detail h1 {
    font-size: 2rem !important;
  }
  .yes {
    margin: 0 !important;
  }
  .main_logo img {
    width: 9rem;
  }
  .nav_btn {
    width: 102px;
    font-size: 12px;
  }
  .upload_container {
    padding: 50px 20px;
  }
}
@media (max-width: 400px) {
  .link {
    font-size: 10px;
  }
  .helpful {
    font-size: 12px !important;
  }
  .yes {
    padding: 4px !important;
  }
  .main_logo img {
    width: 7rem;
  }
  .nav_btn {
    width: 90px;
    font-size: 10px;
  }
  .navbar-toggler-icon {
    width: 1em;
    height: 1em;
  }
  .r_nav {
    gap: 6px !important;
  }
  .follow {
    font-size: 10px;
    padding: 8px 12px !important;
  }
  .cardSpan {
    font-size: 12px;
  }
  .profile_details span {
    font-size: 12px;
  }
  .profile-img > img {
    width: 35px;
  }
  .left_icon {
    gap: 0.6rem !important;
  }
  .left_icon .icon {
    /* font-size: 10px !important; */
    transform: scale(0.8);
  }
  .upload_container div.d-flex.w-100 {
    flex-direction: column-reverse;
  }
  .upload_container .upload_video_btn {
    width: 100%;
    margin: 10px 0;
  }
}
@media (max-width: 300px) {
  .profile-img > img {
    width: 26px;
  }
  .left_icon .icon {
    transform: scale(0.6);
  }
  .left_icon {
    gap: 0.1rem !important;
  }
  .home_bar {
    padding: 0px 10px;
  }
  .profile_details > p {
    font-size: 10px;
  }
  .follow {
    font-size: 6px;
    padding: 8px 12px !important;
  }
  .cardSpan {
    font-size: 8px;
  }
  .profile_details span {
    font-size: 8px;
  }
  .about_music {
    font-size: 10px;
  }
  .avatar_box {
    padding: 0.6rem;
  }
  .avatar_box {
    width: 16rem !important;
  }
  .footer_images > img {
    height: 3rem;
  }
  .main_logo img {
    width: 8rem;
  }
  .sp_detail button {
    width: 100% !important;
  }
  .main_logo img {
    width: 6rem;
  }
  .nav_btn {
    width: 63px;
    font-size: 7px;
  }
}
.video-profile-controls {
  background-color: #ebebeb;
  border-radius: 50%;
  display: grid;
  place-items: center;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
}
.video-profile-controls.active {
  background: #ee353b;
  color: white;
}
.video-profile-controlsMedia {
  border-radius: 50%;
  display: grid;
  place-items: center;
  width: 1.7rem;
  height: 1.7rem;
  cursor: pointer;
}
.copylinkProfile {
  border: none;
  background: transparent;
  outline: none;
  font-weight: 600;
  cursor: pointer;
  padding: 7px 14px;
}
.comment_avatar {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.comment_input {
  outline: none;
  border: none;
  border: 1px solid #eee;

  background-color: #eee;
  padding: 7px 10px;
  border-radius: 10px;
  margin-left: 2.5rem;
  position: relative;
  width: 75%;
}
.comment_input:focus {
  border: 1px solid #979797;
}
.commenticons {
  position: absolute;
  right: 7.4rem;
  bottom: 1.3rem;
}
.scroll_comments {
  height: 13rem;
  overflow-y: scroll;
}
.Profile-avatar {
  width: 120px;
  height: 120px;
}
.Edit-avatar {
  width: 100px;
  height: 100px;
}
.profileText {
  font-size: 18px;
}

.tabUnderline {
  position: relative;
}
.tabUnderline::after {
  content: "";
  width: 100%;
  position: absolute;
  height: 2px;
  border-radius: 1px;
  background: #000;
  left: 0;
  bottom: -6.8px;
}
.videoContainer {
  width: 13rem;
}
.video-comments {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 10px;
}
.video-comments span {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  /* margin: 1px; */
  display: grid;
  place-items: center;
  font-size: 25px;
  transition: all 0.2s linear;
}
.video-comments span:hover {
  background: #eaeaea;
}
.profileVideos {
  width: 100%;
  height: 16rem;
  object-fit: cover;
  border-radius: 7px;
  cursor: pointer;
}
.shareLinks {
  cursor: pointer;
  padding: 10px 13px;
  border-radius: 4px;
  width: 100%;
}
.shareLinks:hover {
  background-color: #f7f5f5;
}
.shareBox {
  width: 18rem;
  position: absolute;
  left: 40.7%;
  top: 24.1%;
  background-color: #ffffff;
  z-index: 1;
  border-radius: 9px;
}
.editIcon {
  height: 2rem;
  width: 2rem;
  border: 1px solid #cccccc;
  position: absolute;
  top: 60%;
  right: 37%;
}
.edit_input > input {
  border-radius: 2px;
  background-color: rgba(22, 24, 35, 0.06);
  border: none;
  padding-inline-start: 12px;
  line-height: 100%;
  outline: none;
  font-size: 14px !important;
  caret-color: rgb(254, 44, 85);
  padding-inline-end: unset;
  width: 100%;
  height: 42px;
}
.edit_input > textarea {
  border-radius: 2px;
  background-color: rgba(22, 24, 35, 0.06);
  border: none;
  line-height: 100%;
  outline: none;
  font-size: 14px !important;
  caret-color: rgb(254, 44, 85);
  padding: 1rem 0 0 1rem;
  width: 100%;
  resize: none;
}
.edit_input {
  width: 23rem;
}
.activeTab {
  border-bottom: 2px solid blue; /* Add your preferred underline styling */
}

/* Style the tab */
.tabProfile {
  overflow: hidden;
  /* border: 1px solid #ccc;
  background-color: #f1f1f1; */
}

/* Style the buttons that are used to open the tab content */
.tabProfile button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
}

/* Change background color of buttons on hover */
.tabProfile button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tabProfile button.activeCards {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
/* navbar search page  */
.search_menu {
  border-bottom: 1px solid rgba(34, 90, 89, 0.2);
}
.search_menu li {
  list-style: none;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  color: rgba(22, 24, 35, 0.5);
}

.search_menu li.active::after {
  content: "";
  position: absolute;
  bottom: -9px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: black;
  transition: all 0.3s ease-in;
}
.search_menu li.active {
  color: #000000 !important;
}

.search_menu li:hover::after {
  width: 100%;
}

.search_menu li:first-child::after {
  width: 100%;
  color: #000000 !important;
}
.main_accounts a {
  text-decoration: none;
}
.account:hover {
  background-color: rgba(235, 235, 235, 0.4);
}
