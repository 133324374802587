@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
.font-1 {
  font-family: "Poppins", sans-serif;
}
.font-2 {
  font-family: "Inter", sans-serif;
}
.btn-primary {
  background: #ec1c24 !important;
  border: none;
  cursor: pointer;
  border-radius: 75px;
  font-weight: 700;
  transition: all 0.2s linear;
  font-size: 15px;
  border: 1px solid #ec1c24 !important;
}
.btn-primary:hover {
  background: #d10d13 !important;
}
.btn-outline-primary {
  color: #ec1c24 !important;
  border: 1px solid #ec1c24 !important;
  border-radius: 75px !important;
}
.btn-outline-primary:hover,
.btn-outline-primary:active {
  background: #ec1c24 !important;
  color: white !important;
}
.bg-primary {
  background: #ec1c24 !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
h6 {
  font-size: 18px;
  font-weight: 600;
}
h5 {
  font-size: 20px;
  font-weight: 700;
}

.light-text {
  color: rgba(22, 24, 35, 0.5);
  font-size: 16px;
}
.line {
  height: 28px;
  width: 1px;
  background: rgba(22, 24, 35, 0.12);
}

.loader {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: grid;
  place-items: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10000;
}
